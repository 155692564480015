import React from "react";
import DashBoard from "./DashBoard";
import classes from"./DashBoard.module.css"

function DashBoardScreen({user,openApps}) {
  return (
    <div>
      <div style={{marginLeft:'12%',marginRight:'12%',marginTop:'60px',marginBottom:'30px'}}>
      <p
        style={{
          fontSize: "24px",
          color: "#737373",
          fontWeight: "bold",
          margin: 0,
        }}>
        Welcome Back
        <span className={classes.userName}>
              {user?.firstName || user?.lastName
                ? `${user?.firstName} ${user?.lastName}`
                : ""}
            </span>
      </p>
      <p style={{ fontSize: "18px", color: "#696969",marginTop: "16px" }}>
        Make your travels easier, safer, and more enjoyable
      </p>
      <DashBoard openApps={openApps}/>
      </div>
      
      {/* <ul>
        <li>
          <a href="http://localhost:3001" target="_blank">
            App1
          </a>
        </li>
        <li>
          <a href="http://localhost:3002" target="_blank">
            App2{" "}
          </a>
        </li>
        <li>
          <a href="http://localhost:3003" target="_blank">
            App3{" "}
          </a>
        </li>
      </ul> */}
    </div>
  );
}

export default DashBoardScreen;
