import React from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

function Routes({ user, signOut }) {

  return user ? <PrivateRoutes user={user} signOut={signOut} /> : <PublicRoutes />;
}

export default Routes;
