import React, { useState } from "react";
import classes from "./AppBar.module.css";
import Martalogo from "../assets/images/MARTA-logo.png";
import { Menu,Drawer } from "glide-design-system";
import {
  AlertsIcon,
  BusStopsIcon,
  CloseIcon,
  FavouritesIcon,
  InfoIcon,
  LostIcon,
  RotuesIcon,
  SeeSayIcon,
  TripPlannerIcon,
} from "../assets/icons/Icons";
import userLogo from "../assets/images/userLogo.svg";

function AppBar({ user, signOut, openApps }) {
  const [openNavMenu, setOpenNavMenu] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenNavMenu = (event) => {
    setOpenNavMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenNavMenu(null);
  };

  const handleOpenProfile = (event) => {
    setOpenProfile(event.currentTarget);
  };

  const handleCloseProfile = (event) => {
    setOpenProfile(null);
  };

  const handleOpenDrawer=()=>
  {
    setOpenNavMenu(true)
  }

  return (
    <div style={{ width: "100%" }}>
      {/* <Drawer open={openDrawer} onClose={()=>
      {
        setOpenDrawer(false)
      }} >
       
       hh
      </Drawer> */}
      <div className={classes.appBarContainer}>
        <div className={classes.appBarContent}>
          <img src={Martalogo} style={{ height: "28px" }} />
        </div>
        <div onClick={handleOpenDrawer} className={classes.mobileView}>
          <span style={{ fontSize: "38px" }} class="material-symbols-outlined">
            menu
          </span>
        </div>
        <div className={classes.appBarContents}>
          <div className={classes.appIcon} onClick={handleOpenNavMenu}>
            <span
              style={{ fontSize: "35px" }}
              class="material-symbols-outlined">
              apps
            </span>
          </div>
          <div></div>
          <div onClick={handleOpenProfile} className={classes.profile}>
            <p>{user?.given_name?.charAt(0)}</p>
          </div>
        </div>
      </div>
      <Menu
        style={{
          marginLeft: "-280px",
          width: "370px",
          borderRadius: "8px",
          height: "auto",
        }}
        id="basic-menu"
        anchorEl={openNavMenu}
        open={Boolean(openNavMenu)}
        onClose={handleClose}>
        <div
          style={{
            marginTop: "8px",
            marginBottom: "16px",
            marginLeft: "16px",
            marginRight: "16px",
          }}>
          <div className={classes.springBoardContents}>
            <p className={classes.springHeader}>Application navigator</p>
            <div style={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon size="22px" color=" #696969" />
            </div>
          </div>
          <div
            style={{
              display: "grid",
              alignSelf: "center",
              marginTop: "12px",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "18px",
            }}>
            <div
              className={classes.menuItem}
              onClick={() => {
                openApps("DashBoard");
              }}>
              <span
                style={{ fontSize: "25px" }}
                class="material-symbols-outlined">
                dashboard
              </span>
              <p className={classes.springContents}>Dashboard</p>
            </div>
            <div
              onClick={() => {
                openApps("Trip Planner");
              }}
              className={classes.menuItem}>
              <TripPlannerIcon size="25px" />
              <p className={classes.springContents}>Bus Tracker</p>
            </div>
             <div
              onClick={() => {
                openApps("Routes");
              }}
              className={classes.menuItem}>
              <RotuesIcon size="25px" />{" "}
              <p className={classes.springContents}>Sustainability in Motion</p>
            </div>
            <div
              onClick={() => {
                openApps("See & Say");
              }}
              className={classes.menuItem}>
              <SeeSayIcon size="25px" />{" "}
              <p className={classes.springContents}>See & Say</p>
            </div>
            <div
              onClick={() => {
                openApps("Lost");
              }}
              className={classes.menuItem}>
              <LostIcon size="25px" />{" "}
              <p className={classes.springContents}>Lost & Found</p>
            </div>
            <div
              onClick={() => {
                openApps("Bus Stops");
              }}
              className={classes.menuItem}>
              <BusStopsIcon size="25px" />{" "}
              <p className={classes.springContents}>Bus Stops</p>
            </div>
            <div
              onClick={() => {
                openApps("Alerts");
              }}
              className={classes.menuItem}>
              <AlertsIcon size="25px" />{" "}
              <p className={classes.springContents}>Alerts</p>
            </div>
            <div
              onClick={() => {
                openApps("Info");
              }}
              className={classes.menuItem}>
              <InfoIcon size="25px" />{" "}
              <p className={classes.springContents}>Info</p>
            </div>
            <div
              onClick={() => {
                openApps("Favourites");
              }}
              className={classes.menuItem}>
              <FavouritesIcon size="25px" />{" "}
              <p className={classes.springContents}>Favourites</p>
            </div>
          </div>
        </div>
      </Menu>
      <Menu
        style={{
          marginLeft: "-280px",
          borderRadius: "8px",
          height: "auto",
          width: "330px",
        }}
        id="basic-menu"
        anchorEl={openProfile}
        open={Boolean(openProfile)}
        onClose={handleCloseProfile}>
        <div
          style={{
            margin: "16px",
          }}>
          <div className={classes.springBoardContents}>
            <p style={{ margin: "0" }} className={classes.springHeader}>
              Profile
            </p>
            <div style={{cursor:'pointer'}} onClick={handleCloseProfile}>
              <CloseIcon size="22px" color=" #696969" />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#f7f7f7",
              padding: "8px",
              borderRadius: "6px",
              marginTop: "12px",
              marginBottom: "12px",
            }}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
             {/* <div style={{width:'50px',height:'50px',borderRadius:'50%',backgroundColor:'#ddd',alignItems:'center',justifyContent:'center'}}>{user?.given_name}</div> */}
              <div>
                <div className={classes.userName}>
                  {user?.given_name || user?.family_name
                    ? `${user?.given_name} ${user?.family_name} `
                    : "-"}
                </div>
                {/* <div className={classes.userEmail}>{user?.email}</div> */}
              </div>
            </div>
          </div>
          <div style={{cursor:'pointer'}}  onClick={signOut} className={classes.logoutContainer}>
            <span class="material-symbols-outlined">logout</span>
            <div>Log Out</div>
          </div>
        </div>
      </Menu>
  
    </div>
  );
}

export default AppBar;
