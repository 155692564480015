import React from "react";
import classes from "./Card.module.css";

function Card({ title, icon, content, openApps }) {
  const navigateApp=()=>
  {
    openApps(title)
  }
  return (
    <div style={{position:'relative'}}>
      <div className={classes.cardContainer}>
        <div className={classes.icon}>
          <div>{icon}</div>
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.content}>{content}</div>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',}}>
        <div onClick={navigateApp} className={classes.button}  style={{width:"85%"}}>Open {title}</div>
        </div>
      </div>
    </div>
  );
}

export default Card;
