import React, { useEffect, useState } from "react";
import Routes from "./routes/Routes";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Login from "./Login";

function Home() {
  const [user, setUser] = useState(null);
  const { instance, inProgress } = useMsal();

  // Fetch access token once when the MSAL interaction is idle
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      getAccessToken();
    }
  }, [inProgress]);

  /**
   * Retrieves the access token silently if available,
   * and updates the user state with token claims.
   */
  const getAccessToken = async () => {
    const request = {
      scopes: ["openid"],
      account: instance.getAllAccounts()[0],
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      console.log("accessToken", response);
      setUser(response?.idTokenClaims);
    } catch (error) {
      console.error("Token acquisition error:", error);
    }
  };

  /**
   * Handles the sign-out action by redirecting to the logout page.
   */
  const signOut = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <AuthenticatedTemplate>
        <Routes user={user} signOut={signOut} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login instance={instance} />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default Home;
