import React from "react";
import Card from "../componenets/Card";
import {
  AlertsIcon,
  BusStopsIcon,
  FavouritesIcon,
  InfoIcon,
  LostIcon,
  RotuesIcon,
  SeeSayIcon,
  TripPlannerIcon,
} from "../assets/icons/Icons";
import classes from "./DashBoard.module.css";

function DashBoard({openApps}) {



  const apps = [
    {
      id: "1",
      title: "Bus Tracker",
      content:
        "Track real-time bus locations and arrival times to stay on schedule.",
      icon: <TripPlannerIcon size="45px" />,
    },
    // {
    //   id: "2",
    //   title: "Routes",
    //   content:
    //     "Route system designed to connect you effortlessly to your destinations. ",
    //   icon: <RotuesIcon size="45px" />,
    // },
    {
      id: "2",
      title: "Sustainability in Motion",
      content:
        "Empowering sustainable choices with insights and options for eco-friendly travel.",
      icon: <RotuesIcon size="45px" />,
    },

    {
      id: "3",
      title: "See & Say",
      content:
        "Report safety concerns, suspicious activities, or emergencies directly to MARTA authorities with this app.",
      icon: <SeeSayIcon size="45px" />,
    },
    {
      id: "4",
      title: "Lost & Found",
      content:
        "Easily report lost belongings, track submissions, and connect with owners through a user-friendly interface",
      icon: <LostIcon size="45px" />,
    },
    {
      id: "5",
      title: "Bus Stops",
      content:
        "Get comprehensive information about bus stops, schedules, and amenities.",
      icon: <BusStopsIcon size="45px" />,
    },
    {
      id: "6",
      title: "Alerts",
      content:
        "Stay informed with timely notifications about important updates and changes.",
      icon: <AlertsIcon size="45px" />,
    },
    {
      id: "7",
      title: "Info",
      content: "Access a centralized hub for essential data and resources.",
      icon: <InfoIcon size="48px" />,
    },
    {
      id: "8",
      title: "Favourites",
      content:
        "Keep track of your preferred items and locations for quick access.",
      icon: <FavouritesIcon size="45px" />,
    },
  ];

  return (
    <div>
      <div style={{ width: "100%" }}>
        {/* <p style={{ fontSize: "24px", color: "#737373", fontWeight: "bold" }}>
          Welcome Back
          <span style={{ fontSize: "24px", color: "#333333" }}>John Doe</span>
        </p>
        <p style={{ fontSize: "16px", color: "#696969" }}>
          Make your travels easier, safer, and more enjoyable
        </p> */}
      </div>

      <div className={classes.cardConatiner}>
        {apps.map((app, i) => {
          return (
            <Card
              title={app?.title}
              icon={app?.icon}
              content={app?.content}
              openApps={openApps}
            />
          );
        })}
      </div>
    </div>
  );
}

export default DashBoard;
