import React, { useEffect, useRef } from 'react';
import { loginRequest } from './authConfig';

const Login = ({ instance }) => {
    const gifContainerRef = useRef(null);

    useEffect(() => {
        instance.loginRedirect(loginRequest).catch((error) => console.log("Login Error:", error));
        localStorage.setItem("accessToken", true);
    
    }, [instance]);

    return (
        <div style={styles.loadingContainer}>
            <div >...Loading
            </div>
        </div>
    );
};

const styles = {
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f4f4',
    },
  
};

export default Login;
