export const BusStopsIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      directions_bus
    </span>
  );
};

export const TripPlannerIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      directions_bus
    </span>
  );
};

export const RotuesIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      eco
    </span>
  );
};

export const SeeSayIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      emergency_share
    </span>
  );
};

export const LostIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      checked_bag
    </span>
  );
};

export const AlertsIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      warning
    </span>
  );
};

export const InfoIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      info
    </span>
  );
};

export const FavouritesIcon = ({ size }) => {
  return (
    <span style={{ fontSize: size }} class="material-symbols-outlined">
      star
    </span>
  );
};

export const CloseIcon = ({size,color}) => {
  return <span style={{ fontSize: size,color:color }} class="material-symbols-outlined">close</span>;
};
