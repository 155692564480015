import React, { useEffect } from 'react'
import { MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest, b2cPolicies } from './authConfig';
import Home from './Home';


function App({instance}) {

  return (
    <MsalProvider instance={instance}>
        <Home/>
    </MsalProvider>
  )
}

export default App