import React from "react";
import AppBar from "../componenets/AppBar";
import DashBoardScreen from "../screens/DashBoardScreen";

function PrivateRoutes({ user, signOut }) {


  const openApps = (app) => {

    if (app === "Bus Tracker") {
      const url = process.env.REACT_APP_TRIP_PLANNER_URL
      window.open(url,"_blank");
    } else if (app === "DashBoard") {
      const url = process.env.REACT_APP_DASHBOARD_URL;
      window.open(url, "_blank");
    } else if (app === "Sustainability in Motion") {
      const url = process.env.REACT_APP_ROUTES_URL;

      window.open(url, "_blank");
    } else if (app === "See & Say") {
      const url = process.env.REACT_APP_SEE_SAY_URL;
      window.open(url, "_blank");
    } else {
      const url = process.env.REACT_APP_OTHER_APP_URL;
      window.open(url, "_blank");
    }
  };
  return (
    <div>
      {window.location.pathname === "/" && (
        <div>
        <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
          <AppBar openApps={openApps} signOut={signOut} user={user} />
        </div>
        <div style={{ marginTop: "94px" }}> {/* Adjust marginTop based on the height of your AppBar */}
          <DashBoardScreen openApps={openApps} user={user} />
        </div>
      </div>
      )}
    </div>
  );
}

export default PrivateRoutes;
